import React, { Component } from "react"
import { default as Timer, Messages as TimerMessages } from "./../../../Timer"

export const AnimationData = {
  twirl: {
    playTime: 1480,
    file: "/media/assistant/apngs/twirl.png"
  },
  winken: {
    playTime: 1080,
    file: "/media/assistant/apngs/winken.png"
  },
  hinundher: {
    playTime: 1560,
    file: "/media/assistant/apngs/hinundher.png"
  },
  gate_ring: {
    playTime: 1760,
    file: "/media/assistant/apngs/gate_ring.png"
  },
  gate_square: {
    playTime: 1840,
    file: "/media/assistant/apngs/gate_square.png"
  },
  rollin: {
    // Becomes gate_square (until rollin is exchanged/deleted in code)
    playTime: 1840,
    file: "/media/assistant/apngs/gate_square.png"
  }
};

const __STANDARD_ANIMATION_HEIGHT = 260;

class AnimatedPNG extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imgLoaded: false,
      imgFinished: false
    };
    this._timerId = null;
    this.image = new Image();
    this._holder = React.createRef();
  }

  componentDidMount() {
    this.image.onload = () => {
      this.setState({ imgLoaded: true }, () => {
        let styles = '';
        if(this.props.id === "twirl" && this._holder.current)
          styles = `top: ${(window.innerHeight - __STANDARD_ANIMATION_HEIGHT - this._holder.current.offsetTop)}px`;
          this._holder.current.setAttribute('style', styles);
        this._timerId = Timer.register({
          maxValue: 10,
          cb: ({msg}) =>  {
            if (msg === TimerMessages.KILLED) return;
            this._holder.current.classList.add('animated');
            this._holder.current.setAttribute('style', '');
            this._timerId = Timer.register({
              maxValue: AnimationData[this.props.id].playTime,
              cb: ({msg}) => {
                if (msg === TimerMessages.KILLED) return;
                this.setState({ imgFinished: true }, () => {
                  this.props.onFinish()
                })
              }
            })
          }
        })
        /*
        setTimeout(() => {
          this.setState({ imgFinished: true }, () => {
            this.props.onFinish()
          })
        }, ANIMATIONDATA[this.props.id].playTime)
        */
      })
    }
    this.image.src = AnimationData[this.props.id].file;
  }

  componentWillUnmount(){
    if (this._timerId) {
      Timer.deregister(this._timerId)
    }
  }

  render() {
    let classes = ["apng"];

    if (!this.state.imgLoaded){
      classes.push("linger");
    }

    return <div ref={this._holder} className={classes.join(" ")}>{this.state.imgLoaded && <img src={this.image.src} alt="Assistant" className="apng-img" />}</div>
  }
}
export default AnimatedPNG
