import Cookies from 'universal-cookie';
import DataFetcher from './utils/DataFetcher';
import dotty from 'dotty';
import diff from 'deep-diff';

const cookies = new Cookies();

const __DEFAULT_USER_DATA = {
  isRegistered: true,
  linkedAccount: null,
  flow: null,
  answers: {
    test: {},
    questionnaire: {}
  },
  timeToSolve: {
    test: {},
    questionnaire: {}
  },
  flags: {
    testFinished: false,
    questFinished: false
  },
  lang: "german",
  consent: {
    maySeeResults: true,
    tutorial: false,
    cookies: false
  },
  created_at: -1,
};

const _clone = (x) => {
  let y = JSON.parse(JSON.stringify(x));
  delete y.personal;
  delete y.isRegistered;
  return y;
};

class User {
  constructor(){
    console.log("User created");
    this._data = Object.assign({}, __DEFAULT_USER_DATA, {"created_at": Date.now()});
    this._savingState = {
      timeoutId: null,
      delay: 100,
      lastSavedObject: this._data.isRegistered ? _clone(this._data) : null,
      save: () => {
        let writeableData = _clone(this._data);

        let differences = diff(this._savingState.lastSavedObject, writeableData);
        if(!differences) return;
        let sendAbles = {
          linkedAccount: this._data.linkedAccount,
          save: {},
          delete: []
        };

        for(let i = 0; i < differences.length; i++){
          if(differences[i] && differences[i].path){
            let dotPath = differences[i].path.join(':');
            switch(differences[i].kind){
              case "N":
              case "E":
                sendAbles.save[dotPath] = differences[i].rhs;
                break;
              case "A":
                sendAbles.save[`${dotPath}.${differences[i].index}`] = differences[i].item.rhs;
                break;
              case "D":
                sendAbles.delete.push(dotPath);
                break;
              default: break;
            }
          }else{
            sendAbles.save.$ = sendAbles.save.$ || {};
            sendAbles.save.$ = Object.assign({}, sendAbles.save.$, JSON.parse(JSON.stringify(differences[i].rhs)));
          }
        }

        DataFetcher.fetch('push/user', sendAbles).then(
          resp => {
            if(!resp) return;
            this._savingState.lastSavedObject = _clone(writeableData);
          }
        );
      }
    };
    console.log("Current Userdata ->", this._data);
  }

  setCookie(ck, value) {
    cookies.set(ck, value, { path: '/' });
  }
  getCookie(ck) {
    return cookies.get(ck);
  }

  setDataEntry(key, value){
    dotty.put(this._data, key, value);
  }

  get lang() {
    return this.getDataEntry('lang');
  }

  setDataEntries(obj){
    if(!obj) return;
    Object.keys(obj).forEach(k => dotty.put(this._data, k, obj[k]));
  }

  getDataEntry(key, defaultValue = null){
    return dotty.get(this._data, key) || defaultValue;
  }

  get isRegistered() {
    return this.getDataEntry('isRegistered')
  }

  setNewFlow(flow) {
    if(!this._data.flow){
      let _flow = JSON.parse(JSON.stringify(flow));
      delete _flow.pool.config;
      let keys = ["test", "questionnaire", "survey"];
      for(let i = 0; i < keys.length; i++){
        let d = dotty.get(_flow, keys[i]);
        dotty.put(_flow, keys[i], d.map(x => x._id));
      }
      this.setDataEntry('flow', _flow);
    }
  }

}

export default new User();
