import React, { Component, Fragment } from "react";
import LocElement from "./LocElement";
import DataFetcher from "../utils/DataFetcher";
class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
      isEvaluating: true
    };
  }

  componentDidMount() {
    if (this.props.type === "survey") {
      this.setState({ result: "right" });
    } else {
      DataFetcher.fetch("validate-exercise", {
        _id: this.props.qID,
        type: this.props.type,
        answers: { ...this.props.data }
      })
        .then(response => {
          let result = this.props.timesUp && response.validation === "wrong" ? "time" : response.validation;
          let delay = result === "time" ? 0 : 250;
          return this.delayedResult(result, delay);
        })
        .catch(error => console.error);
    }
  }

  delayedResult(data, delay) {
    setTimeout(() => {
      this.setState({ result: data });
    }, delay);
  }

  getStatus() {
    let response = { status: "evaluated", value: this.state.result };
    if (this.state.result === "right") response.answers = { ...this.props.data };
    return response;
  }

  renderFeedback() {
    let icons = (
      <div className={`icons ${this.state.result}`}>
        {this.state.result === "right" && (
          <>
            <span className="icon icon-check-circle"></span>
          </>
        )}
        {this.state.result === "wrong" && (
          <>
            <span className="icon icon-error_outline"></span>
          </>
        )}
        {this.state.result === "time" && (
          <>
            <span className="icon icon-clock"></span>
          </>
        )}
      </div>
    );
    let text = (
      <div className="text">
        <LocElement {...this.props.feedbackData[this.state.result]} />
      </div>
    );

    let showTip = false;
    if (this.props.type === "survey") {
      showTip = true;
    } else {
      if (this.props.feedbackData.hasOwnProperty("tip") && this.state.result !== "right") {
        showTip = true;
      }
    }

    let tip = showTip ? (
      <div className="tip">
        <LocElement {...this.props.feedbackData.tip} />
      </div>
    ) : null;
    let button =
      this.state.result !== "right" ? (
        <button className="answer" onClick={this.props.onRepeat}>
          Aufgabe wiederholen
        </button>
      ) : null;
    return (
      <>
        {icons}
        {text}
        {tip}
        {button}
      </>
    );
  }

  renderEvaluatingStatus() {
    return (
      <div className="evaluating">
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        <div className="feedback">
          {!this.state.result && this.renderEvaluatingStatus()}
          {this.state.result && this.renderFeedback()}
        </div>
      </Fragment>
    );
  }
}
export default Feedback;
