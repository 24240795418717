import React, { Fragment } from 'react';
import Blaupause from './Blaupause';

import Header from './../Header';
import SecHeadline from './../SecHeadline';
import GridContainer from './../GridContainer';
import LocElement from './../LocElement';

import Hint from './modules/Hint';
import Gap from './modules/Gap';
import GapFill from './modules/GapFill';
import Foldable from './modules/Foldable';

class Zuordnen extends Blaupause
{
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      modus: 'intro',
      screenCounter: 0
    }
    this._gaps = {};
    this._words = {};
    this._answers = {};
    this._selectedGap = undefined;
    this._selectedWord = undefined;

    this._foldable = React.createRef();
  }

  collectData() {
    let answers = {...this._answers};
    let current = {};
    Object.keys(this._gaps).map((key, i) => {
      return this._gaps[key].current.getData() ? current[key] = this._gaps[key].current.getData() : null;
    });
    answers[this.props.data.items[this.state.screenCounter]._id] = current;
    this._answers = answers;
    return this._answers;
  }

  getGapsLengthById(id) {
    let arr = this.props.data.items.filter(item => item._id === id);
    return Object.keys(arr[0].answersPrefixes).length;
  }

  userMayProceed() {
    let itemCount = this.props.data.items.length;
    let arr = [];
    Object.keys(this._answers).map(key => {
      return Object.keys(this._answers[key]).length === this.getGapsLengthById(key) ? arr.push(key) : null;
    });
    if (arr.length === itemCount) { return "question-finished"; }
    if (this._answers[this.props.data.items[this.state.screenCounter]._id] && Object.keys(this._answers[this.props.data.items[this.state.screenCounter]._id]).length === this.getGapsLengthById(this.props.data.items[this.state.screenCounter]._id)) {
      return "multipart-change";
    }
    return "question-unfinished"
  }

  changeMCP() {
    if (this.userMayProceed() !== "question-unfinished") {
      this.props.sendToMCP('timer advanced');
    } else {
      this.props.sendToMCP('timer');
    }
  }

  pickWord(key) {
    this._selectedWord = key;
    this.checkGapWord();
    this._foldable.current.onCloserClick();
  }

  async pickGap(key) {
    this._selectedGap = key;

    if (this._gaps[this._selectedGap].current.isFilled) {
      let response = await this._gaps[this._selectedGap].current.unfill();
      this._words[response].current.disabled(false)
      this.collectData();
      this.changeMCP();
    }
    
    Object.keys(this._gaps).map((ref, index) => {
      return ref === key ? this._gaps[ref].current.selected(true) : this._gaps[ref].current.selected(false);
    });
    this._foldable.current.open();
    this.checkGapWord();
  }

  checkGapWord() {
    if(!this._selectedGap || !this._selectedWord) return;
    let strg = this.props.data.items[this.state.screenCounter].answers[this._selectedWord].german;
    this._words[this._selectedWord].current.disabled(true);
    let response = this._gaps[this._selectedGap].current.fill(this._selectedWord, strg);
    if (response !== 'empty') this._words[response].current.disabled(false);
    this._selectedGap = this._selectedWord = null;
    this.collectData();
    this.changeMCP();
  }

  render() {

    let itemsToShow = this.props.data.items ? this.props.data.items.slice(this.state.screenCounter*this.props.data.config.maxItemsPerScreen, (this.state.screenCounter+1) * this.props.data.config.maxItemsPerScreen) : [];

    let contentClasses = ['content'];
    if (this.props.data.config.contentAlignment) contentClasses.push(this.props.data.config.contentAlignment);

    let headerClasses = ['test', this.state.modus];

    return (
      <Fragment>
        <Header mode={headerClasses.join(' ')} config={this.props.data} />
        <div id="main" className="template zuordnen">
          {
          // Check if SecHeadline is needed
          ((this.state.modus === "intro" && this.props.data.example) || this.state.modus === "question" || this.state.modus === "evaluate") &&
          <SecHeadline example={this.props.data.example && this.state.modus === 'intro' ? this.props.data.example : null} text={this.props.data.secondaryHeadline} />
          }
          <div className={contentClasses.join(' ')}>
            {
              this.state.modus === "intro" && (

                <Fragment>
                  {

                    this.props.data.example &&
                    this.props.data.example.map((ex, idx) => {

                      let elements = Object.keys(ex.answersPrefixes).map((key, idx) => (
                        <div className="segment" key={`expl-gap-${key}`}>
                          <div className="z-text">
                            <div className="num">{key}.</div>
                            <div className="z-word">
                              <LocElement {...ex.answersPrefixes[key]} />
                            </div>
                          </div>
                          <Gap key={key} cssClasses={['z-gap']} onClick={null} examplefill={ex.answers[ex.validation[key]].german} />
                        </div>
                      ));


                      let answers = Object.keys(ex.answers).map((key, idx) => {
                          return (
                            <GapFill
                              key={`expl-answer-${key}`}
                              onClick={null}
                              text={ex.answers[key]}
                              example={true}
                              disabled={Object.values(ex.validation).includes(key)}
                            />
                          )
                        }
                      );

                      let exampleDescription = this.props.data.exampleDescription ? (
                        <div key={`explDesc-${idx}`} className="xmplDescription">
                          <div className="container">
                            <LocElement {...this.props.data.exampleDescription} />
                          </div>
                        </div>
                      ) : null;

                      return (
                        <Fragment key={`expl-task-${idx}`}>
                          <div className="task xmpl">
                            <div className="left">
                              {elements}
                            </div>
                            <div className="right">
                              <GridContainer className='answers' {...ex.config}>
                                {answers}
                              </GridContainer>
                            </div>
                          </div>
                          {exampleDescription}
                        </Fragment>
                      );

                    })
                  }
                  {
                    this.props.data.hint &&
                    <Hint text={this.props.data.hint} />
                  }
                </Fragment>
              )
            }
            {
              this.state.modus === "question" && (
                itemsToShow.map((item, index) => {
                  let item_id = item._id;
                  let elements = Object.keys(item.answersPrefixes).map((key, idx) => (
                    <div className="segment" key={`gap-${item_id}-${key}`}>
                      <div className="z-text">
                        <div className="num">{key}.</div>
                        <div className="z-word">
                          <LocElement {...item.answersPrefixes[key]} />
                        </div>
                      </div>
                      <Gap ref={this.getOrCreateRef(this._gaps, key)} key={key} cssClasses={['z-gap']} onClick={() => { this.pickGap(key) }} />
                    </div>
                  ));

                  let answers = Object.keys(item.answers).map((key, idx) => (
                    <GapFill
                      key={`answer-${item_id}-${key}`}
                      ref={this.getOrCreateRef(this._words, key)}
                      onClick={(e) => {this.pickWord(key)}}
                      text={item.answers[key]}
                    />
                  ));

                  return (
                    <div key={`task${index}`} className="task">
                      <div className="left">
                        {elements}
                      </div>
                      <Foldable ref={this._foldable} className="right">
                        <GridContainer className='answers' {...item.config}>
                          {answers}
                        </GridContainer>
                      </Foldable>
                    </div>
                  );

                })
              )
            }
            {
              this.state.modus === "evaluate" && (
                this.getFeedbackContent()
              )
            }
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Zuordnen
