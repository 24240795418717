import Quizmaster from './Quizmaster';
import User from './../User';
import DataPersister from './../utils/DataPersister';

import {default as Timer, Messages as TimerMessages} from './../Timer';


class Test extends Quizmaster
{
  constructor(props) {
    super(props);
    this.data = User.getDataEntry('flow.test');
    this.content = DataPersister.getItem('flow').test;
    this._answers = User.getDataEntry('answers.test', {});
    this._timeToSolve = User.getDataEntry('timeToSolve.test', {});
    this._userHasStartedTest = true;
    this.state = {
      currentQuestion: this._userHasStartedTest ? Object.keys(this._answers).length : -1,
      interimScreenFlag: this._userHasStartedTest ? false : true,
      maxQuestions: this.data.length,
      mcpMode: "tracker",
      testMode: "question"
    };
    this._timerId = null;
    this._interimScreenFlag = this.state.interimScreenFlag;
    this._interimScreens = null;
  }

  _deregisterTimers() {
    if(this._timerId) {
      Timer.deregister(this._timerId);
    }
    if (!this._interimScreens) return;
    for(let i = 0; i < this._interimScreens.length; i++)
      Timer.deregister(this._interimScreens[i].timerId);
  }

  componentDidMount() {
    super.componentDidMount();
    if (!this._interimScreens) return;
    this._interimScreens.forEach(interimScreen => {
      if(!interimScreen.time) return;
      interimScreen.timerId = Timer.register({
        maxValue: interimScreen.time,
        cb: (o) => {
          interimScreen.timerId = null;
          if(o.msg === TimerMessages.KILLED) return;
          if(o.msg === TimerMessages.FINISHED) {
            this._interimScreenFlag = true;
          }
        }
      })
    });
  }

  componentWillUnmount() {
    this._deregisterTimers();
  }

  updateAnswers(data) {
    super.updateAnswers(data);
    User.setDataEntry('answers.test', this._answers);
    User.setDataEntry('timeToSolve.test', this._timeToSolve);
  }

  onTestFinish() {
    User.setDataEntry('flags.testFinished', true);
    super.onTestFinish();
  }

  startQuestion(config) {
    super.startQuestion(config);
    this.setMCPMode('timer');
    this.startTimer(config.timer);
    // this.startTimer(3000);
  }

  stopTimer() {
    if(this._timerId) Timer.deregister(this._timerId);
  }

  timesUp (data) {
    window.scroll(0,0);
    this._question.current.disabled(false);
    switch (data.status) {
      case "finish":
        this.updateAnswers(data.answers);
        return this.proceed();
      case "change":
        this.setMCPMode('timer');
        return this.startTimer(data.time);
      default: return;
    }
  }

  startTimer(value) {
    this.mcp.current.showTime(value * 1000);
    if(this._timerId) Timer.deregister(this._timerId);
    this._timerId = Timer.register({
      maxValue: value * 1000,
      cb: (o) => {
        this._timerId = null;
        if(o.msg === TimerMessages.KILLED) return;
        if(o.msg === TimerMessages.FINISHED) {
          return this._question.current.timesUp();
        }
      },
      step: (o) => {
        return this.mcp.current.showTime(value * 1000 - o.msTimePassed);
      }
    })
  }

}

export default Test
