const footernav = {
  headline: {
    "german": "Menü",
    "english": "Menu"
  },
  brandingText: {
    "german": "bereitgestellt durch",
    "english": "provided by"
  },
  entries: [
    {
      "type": "static",
      "german": {
        "text": "Kontakt",
        "textType": "text",
        "href": "kontakt"
      },
      "english": {
        "text": "Contact",
        "textType": "text",
        "href": "kontakt"
      },
      "np_icon": {
        "id": "people_outline"
      }
    },
    {
      "type": "static",
      "german": {
        "text": "Impressum",
        "textType": "text",
        "href": "impressum"
      },
      "english": {
        "text": "Imprint",
        "textType": "text",
        "href": "impressum"
      },
      "np_icon": {
        "id": "fingerprint"
      }
    },
    {
      "type": "static",
      "german": {
        "text": "Datenschutz",
        "textType": "text",
        "href": "datenschutz"
      },
      "english": {
        "text": "Privacy Policy",
        "textType": "text",
        "href": "datenschutz"
      },
      "np_icon": {
        "id": "lock_outline"
      }
    },
    {
      "type": "static",
      "german": {
        "text": "Tutorial",
        "textType": "text",
        "href": "tutorial"
      },
      "english": {
        "text": "Tutorial",
        "textType": "text",
        "href": "tutorial"
      },
      "np_icon": {
        "id": "help"
      }
    }
  ]
};
export default footernav;
