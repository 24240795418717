import React, {Fragment} from 'react';
import Blaupause from './Blaupause';

import Header from './../Header';
import SecHeadline from './../SecHeadline';
import GridContainer from './../GridContainer';
import LocElement from './../LocElement';

import Hint from './modules/Hint';

class MultipleChoice extends Blaupause
{
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      selected: {},
      modus: 'intro',
      screenCounter: 0
    }
  }

  getMaxAnswers(val) {
    return val.config.answerAmount;
  }

  getValidationLengthById(id) {
    let arr = this.props.data.items.filter(item => item._id === id);
    return this.getMaxAnswers(arr[0]);
  }

  userMayProceed() {
    let response = "question-unfinished";
    let control = this.itemsToShow.filter(item => (this.state.selected.hasOwnProperty(item._id) && this.state.selected[item._id].length === this.getValidationLengthById(item._id)));
    if (control.length === this.itemsToShow.length) {
      response = "multipart-change";
      if (this.state.screenCounter + 1 === this.screensLength) {
        response = "question-finished";
      }
    }
    return response;
  }

  setAnswer(item_id, key) {
    let selected = this.state.selected;
    let choices = selected[item_id] || [];
    if (choices.includes(key)) {
      choices = choices.filter(item => item !== key);
    } else {
      if (choices.length === this.getMaxAnswers(this.props.data.items[this.state.screenCounter]) ) return;
      choices.push(key);
    }
    selected[item_id] = choices;
    this.setState({selected: selected}, () => {
      if (this.userMayProceed() !== "question-unfinished") this.props.sendToMCP('timer advanced');
      else this.props.sendToMCP('timer');
    });
  }

  isSelected(item_id, key) {
    return this.state.selected[item_id] && this.state.selected[item_id].includes(key);
  }

  render() {

    this.itemsToShow = this.props.data.items ? this.props.data.items.slice(this.state.screenCounter*this.props.data.config.maxItemsPerScreen, (this.state.screenCounter+1) * this.props.data.config.maxItemsPerScreen) : [];

    let contentClasses = ['content'];
    if (this.props.data.config.contentAlignment) contentClasses.push(this.props.data.config.contentAlignment);
    if (this.props.data.config.maxItemsPerScreen > 1) contentClasses.push('rows');

    let headerClasses = ['test', this.state.modus];

    return (
      <Fragment>
        {/* // NOTE: Examples for Multiple choice will probably need extra Header config */}
        <Header mode={headerClasses.join(' ')} config={this.props.data} />
        <div id="main" className="template multiple-choice">
          {
            // NOTE: Examples for Multiple choice will probably need extra SecHeadline config
            // Check if SecHeadline is needed
            ((this.state.modus === "intro" && this.props.data.example) || this.state.modus === "question" || this.state.modus === "evaluate") &&
            <SecHeadline example={this.props.data.example && this.state.modus === 'intro' ? this.props.data.example : null} text={this.props.data.secondaryHeadline} />
          }
          <div className={contentClasses.join(' ')}>
            {
              this.state.modus === "intro" && (
                <Fragment>
                  {
                    this.props.data.example &&
                    this.props.data.example.map((ex, idx) => {

                      let txt = ex.text ? (
                        <div className="question">
                          <LocElement {...ex.text} />
                        </div>
                      ) : null;

                      let answers = Object.keys(ex.answers).map((key, idx) => {
                        let btnClasses = ['answer', 'xmpl'];
                        if (ex.answers[key].german.textType && ex.answers[key].german.textType === 'svg') btnClasses.push('isImage')
                        if (ex.validation[key] === 1) btnClasses.push('selected');
                        return (
                          <div className="col" key={`expl-answer_${key}`}>
                            <button
                              className={btnClasses.join(' ')}
                              onClick={null}
                              type="button"
                              disabled={false}>
                              <LocElement {...ex.answers[key]} />
                          </button>
                          </div>
                        )
                      });

                      let exampleDescription = this.props.data.exampleDescription ? (
                        <div className="xmplDescription">
                          <div className="container">
                            <LocElement {...this.props.data.exampleDescription} />
                          </div>
                        </div>
                      ) : null;

                      return (
                        <Fragment key={`expl-task${idx}`}>
                          <div className="task">
                            {txt}
                            <GridContainer className='answers' {...ex.config}>
                              {answers}
                            </GridContainer>
                          </div>
                          {exampleDescription}
                        </Fragment>
                      )
                    })
                  }
                  {
                    this.props.data.hint &&
                    <Hint text={this.props.data.hint} />
                  }
                </Fragment>
              )
            }
            {
              this.state.modus === "question" && (
                this.itemsToShow.map((item, index) => {
                  let item_id = item._id;
                  let txt = item.text ? (<div className="question"><LocElement {...item.text} id={`text${index}`} /></div>) : null;
                  let answers = Object.keys(item.answers).map((key, idx) => (
                    <div className="col" key={`answer_${item_id}_${key}`}>
                      <button
                        className={`answer${this.isSelected(item_id, key) ? ' selected' : ''}`}
                        onClick={(e) => {this.setAnswer(item_id, key)}}
                        type="button"
                        disabled={this.state.disabled}>
                        <LocElement {...item.answers[key]} />
                    </button>
                    </div>
                  ));
                  return (
                    <div key={`task${index}`} className="task">
                      {txt}
                      <GridContainer className='answers' {...item.config}>{answers}</GridContainer>
                    </div>
                  );
                })
              )
            }
            {
              this.state.modus === "evaluate" && (
                this.getFeedbackContent()
              )
            }
          </div>
        </div>
      </Fragment>
    );
  }
}

export default MultipleChoice
