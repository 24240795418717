const Events2Use = {
  transitionend: (() => {
    let el = document.createElement("fakeelement");
    let transitions = {
      "transition"      : "transitionend",
      "OTransition"     : "oTransitionEnd",
      "MozTransition"   : "transitionend",
      "WebkitTransition": "webkitTransitionEnd"
    };

    for (let t in transitions){
      if (el.style[t] !== undefined){
        return transitions[t];
      }
    }
  })()
  , animationend: (() => {
    let el = document.createElement("fakeelement");
    let animations = {
      "animation"      : "animationend",
      "OAnimation"     : "oanimationend",
      "MozAnimation"   : "animationend",
      "WebkitAnimation": "webkitAnimationEnd"
    };

    for (let t in animations){
      if (el.style[t] !== undefined){
        return animations[t];
      }
    }
  })()
};

export default Events2Use;
