import React from "react";
import { Switch, Route } from "react-router-dom";

import Hub from "./views/Hub";
import SplashScreen from "./views/SplashScreen";
import Welcome from "./views/Welcome";
import Footer from "./components/Footer";

function App() {
  require("./browserDetection");
  return (
    <div className="App">
      <div id="innerAppWrapper">
        <Switch>
          <Route path="/" component={Welcome} exact />
          <Route path="/hub" component={Hub} exact />
        </Switch>
      </div>
      <Footer />
    </div>
  );
}

export default SplashScreen(App);
