import React, { Component } from "react";
import Feedback from "../Feedback";

class Blaupause extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.screensLength = this.props.data.items.length / this.props.data.config.maxItemsPerScreen;
    this.time = this.props.data.countdown ? this.props.data.countdown / (this.props.data.items.length / this.props.data.config.maxItemsPerScreen) : undefined;
    this.feedbackComponent = React.createRef();
  }

  componentDidMount() {
    this.setState({ timesUp: false })
  }

  collectData() {
    return this.state.selected;
  }

  getOrCreateRef(refs, id) {
    if (!refs.hasOwnProperty(id)) {
      refs[id] = React.createRef();
    }
    return refs[id];
  }

  userMayProceed() {}

  repeatQuestion() {
    this.setState({modus: "intro", screenCounter: 0, selected: {}, timesUp: false });
    this._answers = {};
    this.disabled(false);
  }

  timesUp() {
    this.disabled(true);
    if (this.state.screenCounter + 1 === this.screensLength) {
      this.setState({ modus: "evaluate", timesUp: true });
      this.props.sendToMCP('tracker');
    } else {
      this.setState({ screenCounter: this.state.screenCounter + 1 });
      this.props.timesUp({ status: "change", time: this.time });
    }
  }

  onMCPClick() {
    switch (this.state.modus) {
      case "intro":
        this.setState({ modus: "question" });
        return { status: "start-question", timer: this.time };
      case "evaluate":
        return this.feedbackComponent.current.getStatus();
      default:
        switch (this.userMayProceed()) {
          case "question-finished":
            this.setState({ modus: "evaluate" });
            return { status: "evaluate" };
          case "multipart-change":
            this.setState({ screenCounter: this.state.screenCounter + 1 });
            return { status: this.userMayProceed(), timer: this.time };
          default:
            return { status: this.userMayProceed() };
        }
    }
  }



  getFeedbackContent() {
    return <Feedback ref={this.feedbackComponent} data={this.collectData()} qID={this.props.qID} type={this.props.type} feedbackData={this.props.data.feedback} onRepeat={() => {this.repeatQuestion()}} timesUp={this.state.timesUp} />;
  }

  disabled(bool) {
    this.setState({ disabled: bool });
  }
}

export default Blaupause;
