/* doesn't need document.ready -> THE FAST SHIT */
var root = document.getElementsByTagName('body')[0];
const { detect } = require('detect-browser');
window.browser = detect();
root.setAttribute('class', root.getAttribute('class')+(("ontouchstart" in document.documentElement) ? ' touch' : ' no-touch'));
root.setAttribute('class', (root.getAttribute('class') || '')+' is-'+window.browser.name);
if(window.browser.version){
  var versions = window.browser.version.split('.');
  var versionStr = [];
  for(var i = 0; i < versions.length; i++){
    versionStr.push(versions[i]);
    root.setAttribute('class', (root.getAttribute('class') || '')+' is-'+window.browser.name+'-'+versionStr.join('-'));
  }
  root.setAttribute('class', (root.getAttribute('class') || '')+' is-os-'+window.browser.os.toLowerCase().split(' ').join('-').split('.').join('-'));
}
