import React, { Component, Fragment } from "react"
import Header from "./../components/Header"
import ScreenInTheMiddle from "./../components/ScreenInTheMiddle"
import User from "./../User"
import Localizator from "./../utils/Localizator"
import MCP from "./../components/MCP"
import Test from "./Test"
import Questionnaire from "./Questionnaire"
import Tutorial from "./Tutorial"
import { AnimationTypes } from './../components/assistant/Assistant';

class Hub extends Component {
  constructor(props) {
    super(props)
    this.state = {
      flags: User.getDataEntry("flags"),
      isWelcoming: true,
      tutorialShown: User.getDataEntry("consent.tutorial") || false,
      mcpMode: "proceed"
    }
    this.mcp = React.createRef()
  }

  setFlags() {
    let flags = User.getDataEntry("flags")
    this.setState({ flags })
  }

  getWelcomeText() {
      return {
        headerHead: Localizator.getLocale('uebungstest_einleitung.headline'),
        bigSpeechBubble: Localizator.getLocale('uebungstest_einleitung.speechbubble_1'),
        smallSpeechBubble: Localizator.getLocale('uebungstest_einleitung.speechbubble_2'),
        isUebung: false
      }
  }

  getExitText() {
    return {
      headerHead: Localizator.getLocale('uebungstest_abschluss.headline'),
      bigSpeechBubble: Localizator.getLocale('uebungstest_abschluss.speechbubble_1'),
      smallSpeechBubble: Localizator.getLocale('uebungstest_abschluss.speechbubble_2'),
      isUebung: false,
      assistantMode: AnimationTypes.GATE_SQUARE,
      endScreen: true
    }
  }

  onTutorialFinish() {
    User.setDataEntry("consent.tutorial", true)
    this.setState({ tutorialShown: true })
  }

  allIsDone() {
    return this.state.flags.testFinished && this.state.flags.questFinished;
  }

  onMCPFinalClick () {
    // What's happening when user clicks MCP on exit screen?
    return window.location.reload();
  }

  render() {
    let uebungsText;
    if (this.allIsDone()) {
      uebungsText = this.getExitText()
    } else {
      uebungsText = this.getWelcomeText()
    }

    let content = null
    if (this.state.isWelcoming || Object.values(this.state.flags).reduce((memo, v) => (memo = memo && v)))
      content = (
        <Fragment>
          <Header mode="hub" config={{ headline: uebungsText.headerHead }} />
          <div id="main" className="template hub">
            <div className="content">
              <ScreenInTheMiddle {...uebungsText} />
            </div>
          </div>
          <MCP
            ref={this.mcp}
            onClick={e => {
              this.allIsDone() ? this.onMCPFinalClick() : this.setState({ isWelcoming: false });
            }}
            mode={this.state.mcpMode}
            progress={0}
          />
        </Fragment>
      )
    else {
      if (!this.state.tutorialShown && !this.state.flags.testFinished)
        content = (
          <Fragment>
            <Tutorial />
            <MCP ref={this.mcp} onClick={this.onTutorialFinish.bind(this)} mode={this.state.mcpMode} progress={0} />
          </Fragment>
        )
      if (this.state.tutorialShown &&!this.state.flags.testFinished) content = <Test onFinish={this.setFlags.bind(this)} />
      if (this.state.flags.testFinished && !this.state.flags.questFinished) content = <Questionnaire onFinish={this.setFlags.bind(this)} />
    }
    return <Fragment>{content}</Fragment>
  }
}
export default Hub
