import React, { Component, Fragment } from 'react';
import Header from './../components/Header';
import Carousel from 'nuka-carousel';
import LocElement from './../components/LocElement';
import Localizator from './../utils/Localizator';
import Icon from './../components/Icon';
import {default as Assistant, AnimationTypes } from './../components/assistant/Assistant';

class Slide extends Component
{
  render() {
    return (
      <div className="slide-container-slide">
        <div className="image-container">
          <div className="image">
            <LocElement {...this.props.img} />
          </div>
        </div>
        <div className="text">
          <p>
            <LocElement {...this.props.text} />
          </p>
        </div>
      </div>
    );
  }
}

class Tutorial extends Component
{
  constructor(props) {
    super(props);
    this.state = {
      mcpMode: "tutorial",
      mcpDisabled: false,
      dimension: this.getScreenSize(),
      showAssistant: false,
      header: {
        headline: Localizator.getLocale('tutorial.headline')
      }
    };

    this.windowListener = this.onResize.bind(this);

    this.mcp = React.createRef();

    this.slides = [
      {
        img: Localizator.getLocale('tutorial.tutorial_1_img'),
        text: Localizator.getLocale('tutorial.tutorial_1_text')
      },
      {
        img: Localizator.getLocale('tutorial.tutorial_2_img'),
        text: Localizator.getLocale('tutorial.tutorial_2_text')
      },
      {
        img: Localizator.getLocale('tutorial.tutorial_3_img'),
        text: Localizator.getLocale('tutorial.tutorial_3_text')
      },
      {
        img: Localizator.getLocale('tutorial.tutorial_4_img'),
        text: Localizator.getLocale('tutorial.tutorial_4_text')
      },
      {
        img: Localizator.getLocale('tutorial.tutorial_5_img'),
        text: Localizator.getLocale('tutorial.tutorial_5_text')
      },
      {
        img: Localizator.getLocale('tutorial.tutorial_6_img'),
        text: Localizator.getLocale('tutorial.tutorial_6_text')
      },
      {
        img: Localizator.getLocale('tutorial.tutorial_7_img'),
        text: Localizator.getLocale('tutorial.tutorial_7_text')
      },
      {
        img: Localizator.getLocale('tutorial.tutorial_8_img'),
        text: Localizator.getLocale('tutorial.tutorial_8_text')
      },
      {
        img: Localizator.getLocale('tutorial.tutorial_9_img'),
        text: Localizator.getLocale('tutorial.tutorial_9_text')
      },
      {
        img: Localizator.getLocale('tutorial.tutorial_10_img'),
        text: Localizator.getLocale('tutorial.tutorial_10_text')
      },
      {
        img: Localizator.getLocale('tutorial.tutorial_11_img'),
        text: Localizator.getLocale('tutorial.tutorial_11_text')
      },
      {
        img: Localizator.getLocale('tutorial.tutorial_12_img'),
        text: Localizator.getLocale('tutorial.tutorial_12_text')
      }
    ];

  }

  onMCPClick(e){
    this.setState({ dimension: 'tablet' })
  }

  getProgress(){
    return 0.5;
  }

  buildSlides(dimension) {
    let slides = [];
    let panelNum = dimension === 'desktop' ? 3 : 1;
    let counter = Math.ceil(this.slides.length/panelNum);
    for(let i = 0; i < counter; i++){
      slides.push(this.slides.slice(i*panelNum, i*panelNum+panelNum))
    }
    return slides.map((arr, index) => {
      let panels = arr.map ((panel, idx) => {
        return (
          <Slide key={`p${index}-${idx}`} {...panel} />
        );
      })
      return (
        <div key={`p${index}`} className="slide-container">
          {panels}
        </div>
      );
    });
  }

  onResize() {
    let dimension = this.getScreenSize();
    // let showAssistant = window.innerHeight >= 900;
    let showAssistant = false;
    if (dimension !== this.state.dimension || showAssistant !== this.state.showAssistant) {
      this.controlListener(false);
      return this.setState({ dimension: dimension, showAssistant: showAssistant }, () => {
        this.controlListener(true);
      });
    }
  }

  controlListener(bool) {
    if (bool) {
      window.addEventListener('resize', this.windowListener);
    } else {
      window.removeEventListener('resize', this.windowListener);
    }
  }

  componentDidMount() {
    // onResize Listener
    this.controlListener(true);
    this.onResize();
    // let showAssistant = window.innerHeight >= 900;
    // if (showAssistant !== this.state.showAssistant) {
    //   this.setState({ showAssistant: showAssistant });
    // }
  }

  componentWillUnmount() {
    // onResize Listener
    this.controlListener(false);
  }

  getScreenSize() {
    if (window.innerWidth < 768) return 'mobile';
    if (window.innerWidth < 1024) return 'tablet';
    return 'desktop';
  }

  getSlideWidth(dimension) {
    switch (dimension) {
      case 'mobile': return '320px';
      case 'tablet': return '420px';
      default: return .8;
    }
  }

  render() {
    let withAssistant = this.state.dimension === 'desktop' && this.state.showAssistant;
    let classes = ['main', 'tutorial'];
    if (withAssistant) classes.push('has-assistant');
    let addHeaderProps = this.props.overlayCloser ? this.props : null;
    return (
      <Fragment>
        <Header mode="tutorial" config={this.state.header} {...addHeaderProps} />
        <div id="main" className={classes.join(' ')}>
          {
            this.state.dimension && this.state.dimension === 'desktop' && this.state.showAssistant &&
            <Assistant mode={AnimationTypes.TWIRL} />
          }
          <Carousel
            cellSpacing={20}
            slideWidth={this.getSlideWidth(this.state.dimension)}
            cellAlign="center"
            renderCenterLeftControls={null}
            renderCenterRightControls={null}
            renderBottomCenterControls={({slideCount, goToSlide, currentSlide}) => {
              let leftControl = (
                <button className="skip previous" onClick={goToSlide.bind(null, currentSlide-1)} disabled={currentSlide === 0}>
                  <Icon icon="arrow_back_ios" wrapper="span"/>
                </button>
              );
              let rightControl = (
                <button className="skip next" onClick={goToSlide.bind(null, currentSlide+1)}  disabled={currentSlide === slideCount-1}>
                  <Icon icon="arrow_forward_ios" wrapper="span"/>
                </button>
              );
              let dots = [];
              for(let i = 0; i < slideCount; i++){
                dots.push(<button key={i} className={`dot ${currentSlide === i ? 'active' : ''}`} onClick={goToSlide.bind(null, i)} ></button>);
              }
              return (<div className="controller">{leftControl}<div className="dots-holder">{dots}</div>{rightControl}</div>);
            }}>
            { this.buildSlides(this.state.dimension) }
          </Carousel>
        </div>
      </Fragment>
    );
  }
}

export default Tutorial
