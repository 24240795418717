import React, { Component } from "react";
import Assistant from "./assistant/Assistant";
import SpeechBubble from "./SpeechBubble";
import LocElement from "./LocElement";

class ScreenInTheMiddle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assistantFinished: false,
      assistantMode: props.assistantMode || "idle",
      addAssistantClasses: props.addAssistantClasses || null
    };
  }

  onAssistantStatus(response) {
    if (response.status === "idle") {
      return this.setState({ assistantFinished: true });
    }
  }

  render() {
    let cssClasses = ["screen-in-the-middle"];
    if (this.props.endScreen) cssClasses.push("theEnd");
    if (this.props.addCSSClasses) cssClasses = cssClasses.concat(this.props.addCSSClasses);
    if (!this.state.assistantFinished) {
      cssClasses.push("bubblesHidden");
    }

    return (
      <div className={cssClasses.join(" ")}>
        {!this.props.isUebung && this.props.bigSpeechBubble && this.props.smallSpeechBubble && <SpeechBubble type="main" mode="small" orientation="bottom" text={this.props.bigSpeechBubble} />}
        {!this.props.isUebung && this.props.bigSpeechBubble && !this.props.smallSpeechBubble && <SpeechBubble type="main" mode="big" orientation="bottom" text={this.props.bigSpeechBubble} />}
        <Assistant mode={this.state.assistantMode} animationClasses={this.state.addAssistantClasses} reportStatus={this.onAssistantStatus.bind(this)} />
        {!this.props.isUebung && this.props.bigSpeechBubble && this.props.smallSpeechBubble && <SpeechBubble type="side" mode="small" orientation="top" text={this.props.smallSpeechBubble} />}
        {
          this.props.isUebung && (
            <div className={"uebung-text"}>
              <p><LocElement {...this.props.uebungstestContent} /></p>
            </div>
          )
        }
      </div>
    );
  }
}

export default ScreenInTheMiddle;
