import React, { Component, Fragment } from "react"
import { Redirect } from "react-router-dom"
import MCP from "./../components/MCP"
import Header from "./../components/Header"
import ScreenInTheMiddle from "./../components/ScreenInTheMiddle"
import Localizator from "./../utils/Localizator"
import Overlay from "./../components/Overlay"
import CookieBanner from "./../components/CookieBanner"
import User from "../User"

import { AnimationTypes } from "./../components/assistant/Assistant"

class Welcome extends Component {
  constructor(props) {
    super(props)
    this.state = {
      proceed: false,
      cookie_consent: User.getCookie("cookieConsent") || false,
      mcpMode: "proceed"
    }
    this.mcp = React.createRef()
  }

  getWelcomeText() {
    return {
      bigSpeechBubble: Localizator.getLocale("uebungstest_start.speechbubble_1"),
      smallSpeechBubble: Localizator.getLocale("uebungstest_start.speechbubble_2"),
      assistantMode: AnimationTypes.TWIRL,
      addAssistantClasses: null
    }
  }

  onMCPClick() {
    this.setState({ proceed: true });
  }

  onConsentClick() {
    this.setState({ cookie_consent: true });
    User.setCookie("cookieConsent", true);
  }

  render() {
    if (this.state.proceed) return <Redirect to="/hub" />
    let welcomeText = this.getWelcomeText()
    return (
      <Fragment>
        <Header mode="welcome" config={{ headline: Localizator.getLocale("uebungstest_start.headline") }} />
        <div id="main" className="template welcome">
          <div className="content">
            <ScreenInTheMiddle {...welcomeText} />
          </div>
        </div>
        <MCP ref={this.mcp} onClick={this.onMCPClick.bind(this)} mode={this.state.mcpMode} progress={0} />
        {!this.state.cookie_consent && (
          <Overlay>
            <CookieBanner onConsent={this.onConsentClick.bind(this)} />
          </Overlay>
        )}
      </Fragment>
    )
  }
}

export default Welcome
